<template>
  <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle' width='750px'>
    <div v-if='userColumn&&userColumn.length>0' class='setting-column' v-loading='isSave'>
      <div class='setting-column__title'>
        <span class='move_b'></span>
        <span class='show_b'>序号</span>
        <span class='show_b'>显示</span>
        <span class='name_b'>名称</span>
        <span class='width_b'>宽度</span>
        <span class='sortable_b'>排序</span>
        <span class='fixed_b'>固定</span>
        <span class='fixed_b'>编辑</span>
        <span class='fixed_b'>超出隐藏</span>
      </div>
      <div class='setting-column__list' ref='refColumnSetList'>
        <ul>
          <li v-for='(item,index) in userColumn' :key='`${item.prop}_${index}` '>
          <span class='move_b'>
            <el-tag class='move' style='cursor: move;' effect='plain'>
              <span class='el-icon-rank' style='width: 1em; height: 1em;' /></el-tag>
          </span>
            <span class='move_b'>
              <span>{{ index + 1 }}</span>
            </span>
            <span class='show_b'>
            <el-switch v-model='item.hide' :active-value='false' :inactive-value='true'></el-switch>
          </span>
            <span class='name_b' :title='item.prop'>{{ item.label }}</span>
            <span class='width_b'>
            <el-input v-model='item.width' placeholder='auto'></el-input>
          </span>
            <span class='sortable_b'>
            <el-switch v-model='item.sortable'></el-switch>
          </span>
            <span class='fixed_b'>
            <el-switch v-model='item.fixed'></el-switch>
          </span>
            <span class='fixed_b'>
              <el-switch v-model='item.editable'
                         :disabled='editableCols.indexOf(item.prop)===-1'></el-switch>
            </span>
            <span class='fixed_b'>
              <el-switch v-model='item.overflow'
              ></el-switch>
            </span>
          </li>
        </ul>
      </div>
      <div class='setting-column__bottom'>
        <el-button @click="backDefault('clear')">清空</el-button>
        <el-button @click='backDefault' :disabled='isSave'>重置</el-button>
        <el-button @click='save' type='primary'>保存</el-button>
      </div>
    </div>
    <el-empty v-else description='暂无可配置的列' :image-size='80'></el-empty>
  </el-dialog>

</template>

<script>
import Sortable from 'sortablejs'
import {hex_md5} from '@/utils/md5'

/**
 * 加密
 * @param param 列数组
 * @returns {*|null}
 */
const hash = function(param) {
  return param ? hex_md5(JSON.stringify(param)) : null
}

export default {
  name: 'MColumnSetting',
  components: {
    Sortable
  },
  props: {
    tableName: { type: String, default: '' },
    column: {
      type: [Object, Array],
      default: () => [{
        hide: false,
        prop: 'id',
        label: 'ID',
        width: 120,
        sortable: false,
        fixed: false,
        overflow: false
      }]
    },
    editableCols: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isSave: false,
      userColumn: [],
      dialogTitle: '列设置',
      saveId: null
    }
  },
  watch: {
    userColumn: {
      handler() {
        this.$emit('userChange', this.userColumn)
      },
      deep: true
    }
  },
  mounted() {
    // this.userColumn.length > 0 && this.rowDrop()
  },
  methods: {
    onOpen() {
      this.getLastTableSet()
      // this.dialogTitle = `列设置:${this.tableName}`
    },
    onClose() {
    },
    close() {
      this.$emit('update:visible', false)
    },
    async getLastTableSet() {
      //从缓存读取
      if (localStorage.getItem(`table-column:${this.tableName}`)) {
        const tableSet = JSON.parse(localStorage.getItem(`table-column:${this.tableName}`))
        this.userColumn = tableSet.user_columns
      } else {
        //未读取到，使用默认列设置
        this.userColumn = JSON.parse(JSON.stringify(this.column || []))
      }
      //从服务端获取(服务端若存储了，以服务端为准)
      let { info } = await this.$api.getUserColumnSetting(this.tableName)
      if (info) {
        this.saveId = info.id || null
        if (typeof info.hash === 'undefined' || !info.hash) {
          // 直接重置
          return await this.backDefault()
        } else {
          // 比较哈希
          if (info.hash !== hash(this.column)) {
            return await this.backDefault()
          }
        }

        if (info.user_columns)
          this.userColumn = info.user_columns || []
        else {
          //未设置
          // console.log('未设置列')
          await this.saveData(this.tableName, this.column)
        }
      }

      this.$nextTick(() => {
        //读取之前的列设置
        // this.getLastTableSet()
        if (this.userColumn)
          this.userColumn.length > 0 && this.rowDrop()
      })
    },
    rowDrop() {
      const _this = this
      if (this.$refs.refColumnSetList) {
        const tbody = this.$refs.refColumnSetList.querySelector('ul')
        Sortable.create(tbody, {
          handle: '.move',
          animation: 300,
          ghostClass: 'ghost',
          onEnd({ newIndex, oldIndex }) {
            const tableData = _this.userColumn
            const currRow = tableData.splice(oldIndex, 1)[0]
            tableData.splice(newIndex, 0, currRow)
          }
        })
      }
    },
    //重置
    async backDefault() {
      this.userColumn = [...this.column]

      if (this.saveId) {
        //删除上一次配置
        localStorage.removeItem(`table-column:${this.tableName}`)
        let data = await this.$api.delUserColumnSetting(this.saveId)
        if (data) {
          this.$notify.success('列已重置为默认设置！！！')
        }
      }
      await this.saveData(this.tableName, this.column)

      this.$emit('back', this.column)
    },
    async saveData(tableName, userColumn) {
      if (tableName && userColumn) {
        let postData = {
          table_name: tableName,
          user_columns: userColumn
        }
        localStorage.setItem(`table-column:${tableName}`, JSON.stringify(postData))
        // console.log('save', postData)
        //保存到服务端（持久化）
        let id = await this.$api.saveUserColumnSetting(tableName, userColumn, hash(this.column))
        if (id)
          this.saveId = id
      }
    },
    async save() {
      //保存当前设置
      await this.saveData(this.tableName, this.userColumn)
      //保存到服务端
      this.$emit('save', this.userColumn)
    },
    getDefaultColumns() {
      if (localStorage.getItem(`table-column:${this.tableName}`)) {
        const tableSet = JSON.parse(localStorage.getItem(`table-column:${this.tableName}`))
        let userColumn = tableSet.user_columns
        this.userColumn = [...userColumn]
        this.isColSet = true
      } else {
        this.isColSet = false
        this.userColumn = [...this.column]
      }
    }
  },
  created() {
    this.getLastTableSet()
  }
}
</script>

<style scoped>
.setting-column__title {
  border-bottom: 1px solid #EBEEF5;
  padding-bottom: 15px;
}

.setting-column__title span {
  display: inline-block;
  font-weight: bold;
  color: #909399;
  font-size: 12px;
}

.setting-column__title span.move_b {
  width: 30px;
  margin-right: 15px;
}

.setting-column__title span.show_b {
  width: 60px;
}

.setting-column__title span.name_b {
  width: 160px;
}

.setting-column__title span.width_b {
  width: 60px;
  margin-right: 15px;
}

.setting-column__title span.sortable_b {
  width: 60px;
}

.setting-column__title span.fixed_b {
  width: 60px;
}

.setting-column__list {
  max-height: 800px;
  overflow: auto;
}

.setting-column__list li {
  list-style: none;
  margin: 10px 0;
}

.setting-column__list li > span {
  display: inline-block;
  font-size: 12px;
}

.setting-column__list li span.move_b {
  width: 30px;
  margin-right: 15px;
}

.setting-column__list li span.show_b {
  width: 60px;
}

.setting-column__list li span.name_b {
  width: 160px;
}

.setting-column__list li span.width_b {
  width: 60px;
  margin-right: 15px;
}

.setting-column__list li span.sortable_b {
  width: 60px;
}

.setting-column__list li span.fixed_b {
  width: 60px;
}

.setting-column__list li.ghost {
  opacity: 0.3;
}

.setting-column__bottom {
  border-top: 1px solid #EBEEF5;
  padding-top: 15px;
  text-align: right;
}

/*.dark .setting-column__title {*/
/*  border-color: var(--el-border-color-light);*/
/*}*/

/*.dark .setting-column__bottom {*/
/*  border-color: var(--el-border-color-light);*/
/*}*/
</style>
