<template>
  <div class='default-table'
       v-loading='loading'
       element-loading-text='玩命加载中……'
       element-loading-spinner='el-icon-loading'>
    <el-table
      ref='elTable'
      :data='tableData'
      :height='height'
      v-bind='$attrs'
      v-on='$listeners'>
      <slot name='first-column'></slot>
      <slot name='second-column'></slot>
      <el-table-column
        v-if='showIndex'
        width='50'
        label='序号' header-align='center' align='center' fixed>
        <template slot-scope='scope'>
          <span>{{ offset + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <template v-for='(v,k) in columns'>
        <el-table-column
          :column-key='v.prop'
          :key='k'
          :prop="v.prop?v.prop:typeof(v.field)=='string'?v.prop:''"
          :label='v.label'
          :min-width='v.width'
          :show-overflow-tooltip='v?.overflow||false'
          :header-align="v.header_align?v.header_align:'center'"
          :align="v.align?v.align:'center'"
          :fixed='v.fixed'
          :sortable='v.sortable?v.sortable:false' v-if='!v.hide'>
          <template slot-scope='{row,column}'>
            <div v-if='!v.editable'>
              <div v-if="typeof(v.prop)=='string'">
                <div v-if="v.type === 'image'">
                  <el-image
                    :src='row[column.property]' :alt='row[column.property]' style='width: 100%;height: 100%'
                    width='40px' fit='scale-down'
                    :preview-src-list='[row[column.property]]'>
                    <div slot='error' class='image-slot'>
                      暂无图片
                    </div>
                  </el-image>
                </div>
                <div v-else-if="v.type === 'images'&&Array.isArray(row[column.property])" style='display: flex;'>
                  <el-image
                    v-for='(image,image_index) in row[column.property]' :key='image_index+"image"'
                    :src='image.src' width='40px' fit='scale-down'
                    :preview-src-list='row[column.property].map(_=>{return _.src})' />
                </div>
                <span v-else-if="typeof(v.field)=='string'">
                      {{ row[v.field] || '' }}
                    </span>
                <span v-else-if='v.icon'>
                       {{ row[column.property] }}
                       <i :title="v.editDialog?'双击修改':row[column.property]"
                          :style="v.editDialog?{cursor: 'pointer'}:''" :class='v.icon'></i>
                    </span>
                <span v-else v-html='row[column.property]'></span>
              </div>
            </div>
            <editable-cell v-if='v.editable' :show-input='row.editMode'
                           v-model='row[column.property]' :is-selected='false'
                           @change='editCellVal(row,column.property)' :is-input-number='false'>
                                    <span slot='content'>
                                      <span>{{ row[column.property] }}</span>
                                      <i class='el-icon-edit default-edit-icon'></i>
                                    </span>
            </editable-cell>
          </template>

        </el-table-column>
      </template>
      <slot name='handle-column'></slot>

      <slot />
    </el-table>
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'

export default {
  name: 'MSetTable',
  components: { EditableCell },
  props: {
    tableData: {
      type: Array,
      default() {
        return []
      }
    },
    columns: {
      type: Array,
      default: () => []
    },
    loading: {
      default: false
    },
    showIndex: {
      default: false
    },
    offset: {
      default: 0
    },
    height: {
      type: [String, Number],
      default() {
        return null
      }
    }
  },
  methods: {
    editCellVal(row, field) {
      this.$emit('editCellVal', row, field)
    },
    clearSelection() {
      this.$refs.elTable.clearSelection()
    },
    /**
     * 获取table选中行
     * 父组件用
     */
    interfaceGetSelection: function() {
      return this.$refs.elTable.selection
    }
  }
}
</script>

<style scoped>

</style>
